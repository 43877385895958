import { HttpClient, HttpRequest, HttpResponse } from "../protocol/http/httpClient";

export class FetchHttpClient implements HttpClient {
  async request<T>(data: HttpRequest): Promise<HttpResponse<T>> {
    let response: HttpResponse;
    const payload = {
      method: data.method,
      headers: data.headers,
      body: data.body,
    }

    try {
      response = await fetch(data.url, payload).then((response) => response.json());
    } catch (error) {
      throw new Error('Erro ao fazer requisição');
    }

    return response
  }
}
