import { BrowserRouter } from 'react-router-dom'
import './shared/theme/global.css'
import RoutesComponent from './routes';
import MakeAuthContext from './factory/contexts/AuthContext';

function App() {
  return (
    <BrowserRouter>
      <MakeAuthContext>
        <RoutesComponent />
      </MakeAuthContext>
    </BrowserRouter>
  );
}

export default App;
