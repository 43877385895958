import { GetInfractionTransaction } from "@/domain/useCase/getInfractionTransition"
import { LocalStorageAdapter } from "@/infra/cache/localStorageAdapter";
import { API_ENDPOINTS, makeApiUrl } from "@/infra/http/constants/url";
import { FetchHttpClient } from "@/infra/http/fetchHttpClient";
import { RemotePostInfractionTransaction } from "@/shared/data/useCases/getInfractionTransaction";
import { generateHeaderPrivate } from "@/shared/libs/generate";

export const makeRemoteGetInfractionTransaction = (): GetInfractionTransaction => {
  const cache = new LocalStorageAdapter();
  const user = cache.get('user') as string;
  const account = cache.get('account') as string;

  return new RemotePostInfractionTransaction(
    makeApiUrl(API_ENDPOINTS.Private.INFRACTION_TRANSACTION),
    new FetchHttpClient(),
    generateHeaderPrivate(user, account)
  )
}
