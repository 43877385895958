type Props = {
  title: string
  value: string
}

export const Information = ({ title, value }: Props) => {
  return (
    <div className="flex items-center gap-2">
      <span className="font-semibold text-[#524E69]">{title}:</span>
      <span className="text-[#524E69]">{value}</span>
    </div>
  )
}
