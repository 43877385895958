import { formatCpfCnpj, formatDate, formatToReal } from "../../../../../shared/libs/format";
import { Info } from "../DataRequisition/info";

type Props = {
  data: {
    payer_name: string
    document: string
    value: string
    transition_date: string
    txid: string
    state: string
  }
}

export function DataInfraction({ data }: Props) {
  const [transitionDate, transitionHour] = formatDate(data.transition_date)

  return (
    <div className="flex gap-3 py-4 border-b border-[#E5E5E5]">
      <div className="flex flex-col gap-1 flex-1">
        <span className="font-semibold text-xl">Dados da Infração</span>
        <span className="text-sm">Detalhes da transação financeira que gerou a reclamação</span>
      </div>
      <div className="flex flex-col gap-4 flex-1">
        <Info title="Nome do Pagador:">
          <span>{data.payer_name}</span>
        </Info>
        <Info title="CPF/CNPJ do Pagador:">
          <span>{formatCpfCnpj(data.document)}</span>
        </Info>
        <Info title="Valor:">
          <span>{formatToReal(data.value)}</span>
        </Info>
      </div>
      <div className="flex flex-col gap-4 flex-1">
        <Info title="Data da Transação:">
          <span>{transitionDate}, {transitionHour}</span>
        </Info>
        <Info title="TXID:">
          <span>{data.txid}</span>
        </Info>
        <Info title="Estado:">
          <span>{data.state}</span>
        </Info>
      </div>
    </div>
  )
}
