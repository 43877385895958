import { cn } from "@/shared/libs/utils"

type Props = {
  children: React.ReactNode
  className?: string
}

export function InfoCard({ children, className }: Props) {
  return (
    <div className={cn(
      "border border-[#F8B9C5] rounded bg-[#F3F3F3] px-6 py-4 flex flex-col gap-4",
      className
    )}>
      {children}
    </div>
  )
}
