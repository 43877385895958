import { AppCache } from '@/infra/protocol/cache/cacheClient';
import { isAuthenticated } from '@/shared/libs/verify';
import { Navigate, useLocation } from 'react-router-dom';

type Props = {
  children: JSX.Element
  cache: AppCache
}

const PrivateRoute = ({ children, cache }: Props) => {
  const location = useLocation();

  if (!isAuthenticated(cache)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
