import { MedListProvider } from "@/presentation/Med/contexts/med-list"
import { filterPayload } from "@/shared/libs/generate"
import { makeRemotePostInfractionList } from "../useCases/PostInfractionList"

type Props = {
  children: JSX.Element
}

export const MakeMedListContext = ({ children }: Props) => {
  const payload = filterPayload;
  const remotePostInfractionList = makeRemotePostInfractionList();

  return (
    <MedListProvider basePayload={payload} remotePostInfractionList={remotePostInfractionList}>
      {children}
    </MedListProvider>
  )
}
