import { SubContentWhite } from "../../../../shared/layout/Content";
import { useNavigate, useParams } from "react-router-dom";
import { DataHistoric } from "../../components/Card/DataHistoric";

// const mockData = [
//   {
//     status: "resolved",
//     created_at: "2023-02-01T00:00:00.000Z",
//     deadline: "2023-02-01T00:00:00.000Z",
//     doc_payer: "12345678900",
//     type: "Pedido de reembolso",
//   },
// ]

export default function MedViewHistoric() {
  const navigate = useNavigate();
  const { id } = useParams();

  const handleClick = (url: string) => {
    navigate(url)
  }

  return (
    <>
      <div className="w-full border-b border-[#E5E5E5] flex items-center">
        <span className="text-base font-semibold py-3 px-3 text-states-info cursor-pointer" onClick={() => handleClick(`/med/detalhes/${id}`)}>Detalhe da Infração</span>
        <span className="text-base font-semibold py-3 px-3 border-b-2 border-[#636363] cursor-default">Histórico</span>
      </div>
      <SubContentWhite>
        <div className="flex flex-col gap-4 w-full">
          <DataHistoric />
        </div>
      </SubContentWhite>
    </>
  )
};
