import { cn } from "@/shared/libs/utils"

type Props = {
  children: React.ReactNode
  className?: string
}

export function Content({ children }: Props) {
  return (
    <div className="flex flex-col justify-center items px-4 py-6">
      {children}
    </div>
  )
}

export function SubContentWhite ({ children, className }: Props) {
  return (
    <div className={cn(
      "flex flex-col rounded bg-white p-4 mt-6",
      className
    )}>
      {children}
    </div>
  )
}
