import { useState } from "react";
import { Logo } from "@/shared/components/Logo";
import { Container, SubContainer } from "./components/layout-login";
import { Button } from "@/shared/components/ui/button";
import { useAuth } from "@/shared/context/auth";
import { LoaderCircle } from "lucide-react";
import { EmailInput, PasswordInput } from "./components/input-login";
import { ImageContainer } from "./components/image-login";

export default function Login() {
  const { login } = useAuth();
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const handleLogin = async () => {
    try {
      setLoading(true)
      await login(email, password);
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Container>
      <SubContainer className="bg-primary-dark">
        <ImageContainer src="/img/login.png" alt="Login" />
      </SubContainer>
      <SubContainer className="items-start">
        <div className="flex flex-col py-8 px-10 justify-between h-full w-[467px]">
          <div className="flex flex-col gap-20">
            <div className="flex flex-col gap-8">
              <Logo isMain={true} />
              <span className="text-lg font-medium">Tecnologia em pagamentos.</span>
            </div>

            <div className="flex flex-col gap-4">
              <EmailInput value={email} setValue={setEmail} className="border border-states-border p-3 rounded-md" />
              <PasswordInput value={password} setValue={setPassword} />
              <Button
                className="w-full h-12 text-text-dark bg-[#EFEEF2] font-bold"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading && <LoaderCircle className="animate-spin"/>}
                Entrar
              </Button>
            </div>
          </div>
          <span className="text-xs font-semibold w-full text-center">© 2024 • Treeal • Uma empresa do Grupo Cliqx</span>
        </div>
      </SubContainer>
    </Container>
  )
};

