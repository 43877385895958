import { House, CircleDollarSign, User, ChevronRight } from "lucide-react"
import { Link } from "react-router-dom"

type MenuItemProps = {
  name: string
  link: string
  icon: string
  path?: string
}

export function MenuItem({ name, path, icon, link }: MenuItemProps) {
  return (
    <Link to={link} className="flex justify-between items-center gap-2 py-4 px-4 cursor-pointer">
      <div className="flex gap-2 items-center text-white">
        {icon === 'House' && <House className={path?.includes(link) ? "text-states-error" : "text-tertiary-medium"} />}
        {icon === 'CircleDollar' && <CircleDollarSign className={path?.includes(link) ? "text-states-error" : "text-tertiary-medium"} />}
        {icon === 'User' && <User className={path?.includes(link) ? "text-states-error" : "text-tertiary-medium"} />}
        <span className={path?.includes(link) ? "text-white" : "text-tertiary-medium"}>{name}</span>
      </div>
      <ChevronRight className={path?.includes(link) ? "text-white" : "text-tertiary-medium"} />
    </Link>
  )
}
