import { useState } from "react"
import { Input } from "@/shared/components/ui/input"
import { Label } from "@/shared/components/ui/label"
import { Eye, EyeOff } from "lucide-react"

type Props = {
  value: string
  setValue: (value: string) => void
  className?: string
}

export const PasswordInput = ({ value, setValue }: Props) => {
  const [show, setShow] = useState(false)

  return (
    <div className="flex flex-col gap-2 justify-center">
      <Label htmlFor="password" className="text-primary-dark text-sm font-semibold">Senha</Label>
      <div className="flex border border-states-border p-3 rounded-md h-12">
        <Input id="password" type={show ? "text" : "password"} value={value} onChange={(e) => setValue(e.target.value)} className="bg-transparent h-full w-full focus-visible:outline-none" placeholder="Digite sua senha" />
        <div onClick={() => setShow(!show)} className="cursor-pointer">
          {!show && <Eye />}
          {show && <EyeOff />}
        </div>
      </div>
      {/* <Button variant="link" className="text-secondary-dark self-end p-0">Redefinir senha</Button> */}
    </div>
  )
}

export const EmailInput = ({ value, setValue, className }: Props) => {
  return (
    <div className="flex flex-col gap-2 justify-center">
      <Label htmlFor="email" className="text-primary-dark text-sm font-semibold">Email ou CNPJ</Label>
      <Input id="email" type="email" value={value} onChange={(e) => setValue(e.target.value)} placeholder="Digite seu email ou CNPJ" className={className} />
    </div>
  )
}
