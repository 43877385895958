import { Button } from "../../../../shared/components/ui/button"
import { formatDate } from "../../../../shared/libs/format"
import { cn } from "../../../../shared/libs/utils"
import { Eye, List } from "lucide-react"
import { useNavigate } from "react-router-dom"

type StatusCellProps = {
  status: string
}

export function StatusCell( { status }: StatusCellProps ) {
  return (
    <div className={cn(
      "mx-auto flex w-full justify-center border rounded-full py-1 px-2",
      status === "approved automatic" && "border-states-info",
      status === "urgent" && "border-states-error",
      status === "resolvido" && "border-states-sucess",
    )}>
      <span className={cn(
        "text-sm font-semibold text-center",
        status === "approved automatic" && "text-states-info",
        status === "urgent" && "text-states-error",
        status === "resolvido" && "text-states-sucess",
      )}>{status}</span>
    </div>
  )
}

type DateCellProps = {
  date: string
}

export function DateCell( { date }: DateCellProps ) {
  const [formattedDate, formattedTime] = formatDate(date);

  return (
    <div className="flex flex-col">
      <span className="text-sm font-normal text-left">{formattedDate}</span>
      <span className="text-xs font-normal text-left">às {formattedTime}</span>
    </div>
  )
}

type ActionCellProps = {
  has_response: boolean
  id: string
  finished?: string
}

export function ActionCell({ has_response, finished, id }: ActionCellProps) {
  const navigate = useNavigate()

  // useEffect(() => {
  //   if (finished) {
  //     const [formattedDate] = formatDate(finished)
  //     setDate(formattedDate)
  //   };
  // }, [finished])

  const handleClick = () => {
    navigate(`/med/detalhes/${id}`)
  }

  return (
    <div className="flex gap-2 items-center justify-start w-full">
      <Button variant="ghost" className="text-states-info p-0 w-9 h-9 flex justify-center items-center" onClick={handleClick}>
        <Eye size={24}/>
      </Button>
      <Button variant="ghost" className="text-states-info p-0 w-9 h-9 flex justify-center items-center">
        <List size={24}/>
      </Button>
      {has_response && <Button className="bg-[#AB4812] text-white font-bold hover:bg-[#8F3B0A]" onClick={handleClick}>Responder</Button>}
      {finished &&
        <div className="font-normal text-sm flex flex-col">
          <span>Processado:</span>
          <span>{finished}</span>
        </div>
      }
    </div>
  )
}
