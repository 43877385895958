import { PayloadModel } from "@/domain/model/payloadModel";
import { PostInfractionList } from "@/domain/useCase/postInfractionList";
import { HttpClient, HttpStatusCode } from "@/infra/protocol/http/httpClient";

export class RemotePostInfractionList implements PostInfractionList {
  constructor(
    public url: string,
    public httpClient: HttpClient,
    public headers: any
  ) {
    this.url = url;
    this.httpClient = httpClient;
    this.headers = headers;
  }

  async execute(payload: PayloadModel): Promise<any> {
    const payloadJSON = JSON.stringify(payload);

    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      headers: this.headers,
      body: payloadJSON
    });

    switch (response.status) {
      case HttpStatusCode.serverError:
        throw new Error('Erro no servidor');
      default:
        return response.data;
    }
  }

}
