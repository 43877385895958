import { AppCache } from "@/infra/protocol/cache/cacheClient";

export const isAuthenticated = (cache: AppCache) => {
  const token = cache.get('user');
  return !!token;
};

export const verifyEmail = (email: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const verifyPassword = (password: string) => {
  return password.length >= 6;
};
