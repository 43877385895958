import { Outlet } from "react-router-dom"
import { Sidebar } from "../Sidebar"
import { Header } from "../Header"
import { Content } from "../Content"

export function Main() {
  return (
    <main className="flex w-full h-screen">
      <Sidebar/>
      <div className="w-full h-full flex flex-col bg-[#F3F3F3]">
        <Header />
        <Content>
          <Outlet />
        </Content>
      </div>
    </main>
  )
}
