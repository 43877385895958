import PublicRoute from "@/routes/components/public-route"
import { LocalStorageAdapter } from "@/infra/cache/localStorageAdapter";

function makePublicRoute(children: JSX.Element) {
  const cache = new LocalStorageAdapter();

  return (
    <PublicRoute cache={cache}>
      {children}
    </PublicRoute>
  )
}

export default makePublicRoute;
