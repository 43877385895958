import { LoginModel } from "@/domain/model/loginModel";
import { Login } from "@/domain/useCase/login";
import { HttpClient, HttpStatusCode } from "@/infra/protocol/http/httpClient";
import { convertPasswordToBase64 } from "@/shared/libs/convert";

export class RemoteLogin implements Login {
  constructor(
    public url: string,
    public httpClient: HttpClient,
    public headers: any
  ) {
    this.url = url;
    this.httpClient = httpClient;
    this.headers = headers;
  }

  async execute(email: string, password: string): Promise<LoginModel> {
    const payloadJSON = JSON.stringify({
      username: email,
      password: convertPasswordToBase64(password),
    })

    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      headers: this.headers,
      body: payloadJSON
    });

    switch (response.status) {
      case HttpStatusCode.serverError:
        throw new Error('Erro no servidor');
      default:
        return response.data;
    }
  }

}
