import { useState } from "react";
import { Textarea } from "../../../../../shared/components/ui/textarea";

export function DataResponse() {
  const [response, setResponse] = useState("")

  return (
    <div className="flex gap-3 py-4 border-b border-[#E5E5E5]">
      <div className="flex flex-col gap-1">
        <span className="font-semibold text-xl">Resposta da Instituição</span>
        <span className="text-sm">Por favor, insira o máximo de detalhes para validar o posicionamento em relação à solicitação</span>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <span className="font-semibold text-sm">Defesa (até 250 caracteres):</span>
        <Textarea
          placeholder="Descrição de resposta validando a recusa aqui..."
          className="h-24 w-full bg-[#EFEEF2] resize-none"
          maxLength={250}
          value={response}
          onChange={(e) => setResponse(e.target.value)}
        />
      </div>
    </div>
  )
}
