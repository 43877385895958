import { LocalStorageAdapter } from "@/infra/cache/localStorageAdapter";
import PrivateRoute from "@/routes/components/private-route";

function makePrivateRoute(children: JSX.Element) {
  const cache = new LocalStorageAdapter();

  return (
    <PrivateRoute cache={cache}>
      {children}
    </PrivateRoute>
  )
}

export default makePrivateRoute;
