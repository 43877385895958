import { Separator } from "@/shared/components/ui/separator";
import { formatToReal } from "@/shared/libs/format";

export function Balance() {
  return (
    <div className="flex items-center gap-2 border rounded h-12 px-3">
      <div className="flex flex-col">
        <span className="text-[10px] font-semibold">
          Saldo
        </span>
        <span className="text-sm text-states-sucess font-semibold">
          {formatToReal("270168.00")}
        </span>
      </div>
      <Separator orientation="vertical" className="h-3/4" />
      <div className="flex flex-col">
        <span className="text-[10px] font-semibold">
          Saldo Bloqueado
        </span>
        <span className="text-sm text-states-error font-semibold">
          {formatToReal("270168.00")}
        </span>
      </div>
    </div>
  )
}
