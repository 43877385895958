import { Login } from "@/domain/useCase/login";
import { API_ENDPOINTS, makeApiUrl } from "@/infra/http/constants/url";
import { FetchHttpClient } from "@/infra/http/fetchHttpClient";
import { RemoteLogin } from "@/shared/data/useCases/login";
import { generateHeaderPublic } from "@/shared/libs/generate";

export const makeRemoteLogin = (): Login => {
  return new RemoteLogin(
    makeApiUrl(API_ENDPOINTS.Public.LOGIN),
    new FetchHttpClient(),
    generateHeaderPublic()
  )
}
