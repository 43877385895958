import { cn } from "@/shared/libs/utils"

type Props = {
  isMain?: boolean
}

const LOGO_MAIN = '/img/logo-main.png'
const LOGO_SUB = '/img/logo.png'

export function Logo({ isMain = false }: Props) {
  return (
    <div className={cn(
      isMain ? "w-[152px] h-[48px]" : "w-[74px] h-6"
    )}>
      <img src={isMain ? LOGO_MAIN : LOGO_SUB} alt="Logo" className="w-full h-auto" />
    </div>
  )
};
