import { Bell } from "lucide-react";

type Props = {
  news: number | string;
}

export function Notification({ news }: Props) {
  return (
    <div className="flex items-center justify-center relative">
      <Bell className="text-primary-dark" size={24}/>
      {news !== 0 && <span className="absolute rounded-full bg-primary-dark bottom-4 left-3 w-5 h-5 flex items-center justify-center text-white text-xs">{news}</span>}
    </div>
  )
}
