/* eslint-disable react-hooks/exhaustive-deps */
import { SubContentWhite } from "../../../../shared/layout/Content";
import { useNavigate, useParams } from "react-router-dom";
import { DataRequisition } from "../../components/Card/DataRequisition";
import { DataInfraction } from "../../components/Card/DataInfraction";
import { DataResponse } from "../../components/Card/DataResponse";
import { GetInfractionTransaction } from "@/domain/useCase/getInfractionTransition";
import { useEffect, useState } from "react";

const mockData = {
  detail: {
    status: "resolved",
    created_at: "2023-02-01T00:00:00.000Z",
    deadline: "2023-02-01T00:00:00.000Z",
    doc_payer: "12345678900",
    type: "Pedido de reembolso",
  },
  infraction: {
    payer_name: "Antonia Maria Lima",
    transition_date: "2023-02-01T00:00:00.000Z",
    value: "253.80",
    document: "12345678900",
    txid: "[numero_txid]",
    state: "São Paulo",
  },
}

type Props = {
  remoteGetInfractionTransaction: GetInfractionTransaction
}

export default function MedViewDetail({ remoteGetInfractionTransaction }: Props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (id && !hasFetched) {
      getInfractionTransaction(id)
      setHasFetched(true)
    }
  },[hasFetched])

  const getInfractionTransaction = async (infraId: string) => {
    try {
      const response = await remoteGetInfractionTransaction.execute(infraId);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = (url: string) => {
    navigate(url)
  }

  return (
    <>
      <div className="w-full border-b border-[#E5E5E5] flex items-center">
        <span className="text-base font-semibold border-b-2 border-[#636363] py-3 px-3 cursor-default">Detalhe da Infração</span>
        <span className="text-base font-semibold py-3 px-3 text-states-info cursor-pointer" onClick={() => handleClick(`/med/historico/${id}`)}>Histórico</span>
      </div>
      <SubContentWhite>
        <div className="flex flex-col gap-4 w-full">
          <DataRequisition data={mockData.detail} />
          <DataInfraction data={mockData.infraction}/>
          <DataResponse />
        </div>
      </SubContentWhite>
    </>
  )
};
