
import { WorkspaceModel } from "@/domain/model/workspaceModel";
import { GetWorkspaces } from "@/domain/useCase/getWorkspaces";
import { HttpClient, HttpStatusCode } from "@/infra/protocol/http/httpClient";

export class RemoteGetWorkspaces implements GetWorkspaces {
  constructor(
    public url: string,
    public httpClient: HttpClient,
    public headers: any
  ) {
    this.url = url;
    this.httpClient = httpClient;
    this.headers = headers;
  }

  async execute(): Promise<WorkspaceModel[]> {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'get',
      headers: this.headers,
    });

    switch (response.status) {
      case HttpStatusCode.serverError:
        throw new Error('Erro no servidor');
      default:
        return response.data;
    }
  }

}
