
/* eslint-disable react-hooks/exhaustive-deps */
import { UserModel } from '@/domain/model/userModel';
import { WorkspaceModel } from '@/domain/model/workspaceModel';
import { GetWorkspaces } from '@/domain/useCase/getWorkspaces';
import { Login } from '@/domain/useCase/login';
import { LocalStorageAdapter } from '@/infra/cache/localStorageAdapter';
import { useToast } from '@/shared/hooks/use-toast';
import { verifyEmail, verifyPassword } from '@/shared/libs/verify';
import { createContext, useState, ReactNode, useContext, useEffect } from 'react';

type AuthContextProps = {
  user: UserModel | null;
  workspaces: WorkspaceModel[] | null;
  getAccountId: () => unknown;
  setAccountId: (accountId: string) => void;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const KEY_CACHE_USER = 'user';
const KEY_CACHE_ACCOUNT = 'account';

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
  remoteLogin: Login;
  remoteGetWorkspaces: GetWorkspaces;
  cache: LocalStorageAdapter;
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export const AuthProvider = ({ children, remoteLogin, cache, remoteGetWorkspaces }: AuthProviderProps) => {
  const [user, setUser] = useState<UserModel | null>(null);
  const [workspaces, setWorkspaces] = useState<WorkspaceModel[] | null>(null);
  const { toast } = useToast();

  const login = async (email: string, password: string) => {
    if (!verifyEmail(email)) {
      toast({
        variant: "destructive",
        title: "Email inválido",
        description: "Verifique se o email está correto",
      })
      throw new Error('Email inválido');
    }

    if (!verifyPassword(password)) {
      toast({
        variant: "destructive",
        title: "Senha inválida",
        description: "A senha deve conter no mínimo 6 caracteres",
      })
      throw new Error('Senha inválida');
    }

    try {
      const reponse = await remoteLogin.execute(email, password);

      setUser(reponse.userData);
      cache.set(KEY_CACHE_USER, JSON.stringify(reponse.token));
      cache.set(KEY_CACHE_ACCOUNT, JSON.stringify(reponse.userData.accounts[0]));

      toast({
        variant: "default",
        title: "Login efetuado com sucesso",
      })

      window.location.reload();
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Houve um erro ao tentar fazer login",
      })
    }
  };

  const getWorkspaces = async () => {
    const response = await remoteGetWorkspaces.execute();

    if (Object.keys(response).length === 0) {
      logout();
      return
    }

    setWorkspaces(response);
  };

  const getAccountId = () => {
    return cache.get(KEY_CACHE_ACCOUNT);
  }

  const setAccountId = (accountId: string) => {
    cache.set(KEY_CACHE_ACCOUNT, accountId);
  }

  const logout = () => {
    setUser(null);
    cache.remove(KEY_CACHE_USER);
    cache.remove(KEY_CACHE_ACCOUNT);
    window.location.reload();
  };

  useEffect(() => {
    const user = cache.get(KEY_CACHE_USER);
    if (user) {
      getWorkspaces();
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, workspaces, getAccountId, setAccountId, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
