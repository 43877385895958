import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shared/components/ui/select";
import { useMedList } from "../../contexts/med-list";

export function Order() {
  const { updateOrder } = useMedList();

  const handleChange = (value: string) => {
    updateOrder(value);
  }

  return (
    <Select onValueChange={(e) => handleChange(e)}>
      <SelectTrigger className="w-44 h-9 bg-">
        <SelectValue placeholder="Ordenar por: Valor" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="desc">Menor</SelectItem>
          <SelectItem value="asc" defaultChecked>Maior</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
