type Props = {
  title: string,
  children: React.ReactNode
}

export function Info({ title, children }: Props) {
  return (
    <div className="flex gap-2 items-center">
      <span className="font-semibold text-sm">{title}</span>
      <span>{children}</span>
    </div>
  )
}
