export const MENU_OPTIONS = [
  {
    name: 'Página inicial',
    path: '/dashboard',
    icon: 'House',
  },
  {
    name: 'Med',
    path: '/med',
    icon: 'CircleDollar',
  },
  {
    name: 'Usuários',
    path: '/usuarios',
    icon: 'User',
  }
]
