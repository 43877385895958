import { Input } from "../../../shared/components/ui/input";
import { Search } from "lucide-react";

type Props = {
  value: string
  setValue: (value: string) => void
  className?: string
}

export function SearchInput({ value, setValue }: Props) {
  return (
    <div className="flex border border-states-border p-3 rounded-md h-10 items-center">
      <Input id="password" type="text" value={value} onChange={(e) => setValue(e.target.value)} className="bg-transparent h-full w-full focus-visible:outline-none" placeholder="Digite sua senha" />
      <Search className="text-states-info" />
    </div>
  )
}
