
import { GetWorkspaces } from "@/domain/useCase/getWorkspaces";
import { LocalStorageAdapter } from "@/infra/cache/localStorageAdapter";
import { API_ENDPOINTS, makeApiUrl } from "@/infra/http/constants/url";
import { FetchHttpClient } from "@/infra/http/fetchHttpClient";
import { RemoteGetWorkspaces } from "@/shared/data/useCases/getWorkspaces";
import { generateHeaderPrivate } from "@/shared/libs/generate";

export const makeRemoteGetWorkspaces = (): GetWorkspaces => {
  const cache = new LocalStorageAdapter();
  const user = cache.get('user') as string;
  const account = cache.get('account') as string;

  return new RemoteGetWorkspaces(
    makeApiUrl(API_ENDPOINTS.Private.WORKSPACE),
    new FetchHttpClient(),
    generateHeaderPrivate(user, account)
  )
}
