import { Button } from "@/shared/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/shared/components/ui/popover"
import { Calendar } from "@/shared/components/ui/calendar"
import { cn } from "@/shared/libs/utils"
import { CalendarIcon } from "lucide-react"
import { format } from "date-fns"
import { ptBR } from 'date-fns/locale';
import { Label } from "@/shared/components/ui/label"
import { Input } from "@/shared/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/shared/components/ui/select"

type CalenderInputProps = {
  label: 'startDate' | 'endDate'
  date?: Date
  setDate: (type: "startDate" | "endDate", date: Date | undefined) => void
}

export const CalendarInput = ({ label, date, setDate }: CalenderInputProps) => {
  const formattedDate = date ? format(date, 'dd/MM/yyyy', { locale: ptBR }) : '';

  return (
    <div className="flex flex-col gap-1">
      <Label htmlFor={label} className="font-semibold text-primary-dark text-sm">{label === 'startDate' ? 'Data Inicial' : 'Data Final'}</Label>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            id={label}
            className={cn(
              "w-[280px] h-12 justify-between px-3 text-left font-normal border-[#C6C3D0]",
              !date && "text-muted-foreground"
            )}
          >
            {date ? formattedDate : <span>02/09/2024</span>}
            <CalendarIcon className="mr-2 h-4 w-4 text-states-info" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            onSelect={(e) => setDate(label, e)}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

type GeneralInputProps = {
  label: string
  value: string
  setValue: (value: string) => void
  id: string
  placeholder?: string
  className?: string

}

export const GeneralInput = ({ label, value, setValue, id, placeholder, className }: GeneralInputProps) => {
  return (
    <div className="flex flex-col gap-1 flex-1">
      <Label htmlFor={id} className="text-primary-dark text-sm font-semibold">{label}</Label>

      <Input
        id={id}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={
          cn(
            "bg-transparent h-12 w-full focus-visible:outline-none border border-[#C6C3D0] rounded p-3",
            className
          )
        }
        placeholder={placeholder} />
    </div>
  )
}

type GeneralDateInputProps = {
  label: string
  value: Date | undefined
  setValue: (value: Date | undefined) => void
  id: string
  placeholder?: string
  className?: string
}

export const GeneralDateInput = ({ label, value, setValue, id, placeholder, className }: GeneralDateInputProps) => {
  const formattedDate = value ? format(value, 'dd/MM/yyyy', { locale: ptBR }) : '';

  return (
    <div className="flex flex-col gap-1 flex-1">
      <Label htmlFor={id} className="font-semibold text-primary-dark text-sm">{label}</Label>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            id={id}
            className={cn(
              "justify-between px-3 h-12 text-left font-normal border-[#C6C3D0]",
              !value && "text-muted-foreground",
              className
            )}
          >
            {value ? formattedDate : <span>{placeholder}</span>}
            <CalendarIcon className="mr-2 h-4 w-4 text-states-info" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={value}
            onSelect={(e) => setValue(e)}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export const SelectInput = ({ label, value, setValue, id, placeholder }: GeneralInputProps) => {
  return (
    <div className="flex flex-col gap-1 flex-1">
      <Label htmlFor={id} className="font-semibold text-primary-dark text-sm">{label}</Label>

      <Select value={value} onValueChange={(e) => setValue(e)}>
        <SelectTrigger className="w-full h-12 focus:ring-0 border-[#C6C3D0]" id={id}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="every" defaultChecked>Todos</SelectItem>
          <SelectItem value="open">Aberto</SelectItem>
          <SelectItem value="pending">Pendente</SelectItem>
        </SelectContent>
      </Select>
    </div>
  )
}
