import ReactPaginate from "react-paginate";
import { useMedList } from "../../contexts/med-list";
import "./styles.css";

export const Pagination = () => {
  const { pagination, updatePagination } = useMedList();

  const handlePageChange = (page: { selected: number }) => {
    updatePagination(page.selected);
  }

  return (
    <>
      {pagination &&
        <div id="solicitationPaginateContainer">
          <ReactPaginate
            breakLabel="..."
            nextLabel="›"
            previousLabel="‹"
            previousClassName="prev-next-button"
            nextClassName="prev-next-button"
            onPageChange={handlePageChange}
            marginPagesDisplayed={3}
            pageRangeDisplayed={2}
            pageCount={pagination.total - 200}
            forcePage={pagination.page}
          />
        </div>
      }
    </>
  )
}
