import { LocalStorageAdapter } from "@/infra/cache/localStorageAdapter";
import { AuthProvider } from "@/shared/context/auth";
import { makeRemoteGetWorkspaces } from "../useCases/GetWorkspaces";
import { makeRemoteLogin } from "../useCases/Login";

type Props = {
  children: JSX.Element
}

function MakeAuthContext({ children }: Props) {
  const remoteLogin = makeRemoteLogin();
  const remoteGetWorkspaces = makeRemoteGetWorkspaces();
  const cache = new LocalStorageAdapter();

  return (
    <AuthProvider cache={cache} remoteLogin={remoteLogin} remoteGetWorkspaces={remoteGetWorkspaces}>
      {children}
    </AuthProvider>
  )
}

export default MakeAuthContext;
