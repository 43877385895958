import { Main } from "../shared/layout/Main";
import Dashboard from "../presentation/Dashboard";
import Login from "../presentation/Login";
import { Navigate, Route, Routes } from "react-router-dom";
import MedViewHistoric from "../presentation/Med/pages/view/historic";
import makePublicRoute from "@/factory/routes/public-route";
import makePrivateRoute from "@/factory/routes/private-route";
import { MakeMedList } from "@/factory/pages/MedList";
import { MakeMedDetails } from "@/factory/pages/MedDetails";

export default function RoutesComponent() {
  return (
    <Routes>
      <Route path="/login" element={
        makePublicRoute(<Login />)
      } />

      <Route element={
        makePrivateRoute(<Main />)
      }>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/med" element={<MakeMedList />} />
        <Route path="/med/detalhes/:id" element={<MakeMedDetails />} />
        <Route path="/med/historico/:id" element={<MedViewHistoric />} />

      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  )
};
