import { cn } from "@/shared/libs/utils";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const Container = ({ children }: Props) => (
  <div className="flex w-full h-screen justify-center items-center">
    <div className="h-full flex w-full">
      {children}
    </div>
  </div>
);

export const SubContainer = ({ children, className }: Props) => (
  <div className={cn("flex flex-col items-center justify-center w-1/2 h-full", className)}>
    {children}
  </div>
);
