import { Button } from "@/shared/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/shared/components/ui/popover";
import { useAuth } from "@/shared/context/auth";

type Props = {
  photo: string;
  name: string;
  account: string;
}

export function Profile({ photo, name, account }: Props) {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" className="flex gap-2" >
          <div className="flex gap-2">
            <div className="w-9 h-9 rounded-full overflow-hidden object-fill">
              <img src={photo} alt="profile" className="w-full h-full" />
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-semibold text-primary-dark">{name}</span>
              <span className="font-semibold text-[10px]">{account}</span>
            </div>
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-80">
        <Button variant="outline" className="w-full" onClick={handleLogout}>
          Sair
        </Button>
      </PopoverContent>
    </Popover>
  )
}
