import MedList from "@/presentation/Med/pages/list"
import { MakeMedListContext } from "../contexts/MedList"

export const MakeMedList = () => {
  return (
    <MakeMedListContext>
      <MedList />
    </MakeMedListContext>
  )
}
