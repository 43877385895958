/* eslint-disable react-hooks/exhaustive-deps */
import { MedListModel } from '@/domain/model/medListModel';
import { FilterByT, PayloadModel } from '@/domain/model/payloadModel';
import { PostInfractionList } from '@/domain/useCase/postInfractionList';
import { useToast } from '@/shared/hooks/use-toast';
import { createContext, useState, useEffect, ReactNode, useContext } from 'react';

type MedListContextProps = {
  medList: MedListModel[] | null
  payload: PayloadModel
  pagination: PaginationType | null
  updatePagination: (page: number) => void
  updateOrder: (order: string) => void
  updateFilter: (filter: FilterByT) => void
}

const MedListContext = createContext<MedListContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode
  basePayload: PayloadModel
  remotePostInfractionList: PostInfractionList
}

export const useMedList = () => {
  const context = useContext(MedListContext);

  if (!context) {
    throw new Error('useMedList must be used within a MedListProvider');
  }

  return context;
};

type PaginationType = {
  page: number
  pageSize: number
  total: number
}

export const MedListProvider = ({ children, basePayload, remotePostInfractionList }: AuthProviderProps) => {
  const [medList, setMedList] = useState<MedListModel[] | null>(null);
  const [pagination, setPagination] = useState<PaginationType | null>(null);
  const [payload, setPayload] = useState(basePayload);

  const { toast } = useToast();

  const postInfractionList = async () => {
    try {
      const response = await remotePostInfractionList.execute(payload);

      if (Object.keys(response).length === 0) {
        setMedList([]);
        return
      }

      setMedList(response.infractions);
      setPagination(response.pagination);
    } catch (error) {
      console.error(error);
      toast({
        variant: "destructive",
        title: "Houve um erro ao tentar carregar as solicitações",
      })
    }
  }

  const updatePayload = (payload: PayloadModel) => {
    setPayload(payload);
  }

  const updateFilter = (filter: FilterByT) => {
    updatePayload({ ...payload, filterBy: filter });
    toast({
      variant: "default",
      title: "Filtro atualizado",
    })
  }

  const updateOrder = (order: string) => {
    updatePayload({ ...payload, order });
    toast({
      variant: "default",
      title: "Ordenação atualizada",
    })
  }

  const updatePagination = (page: number) => {
    updatePayload({ ...payload, page });
  }

  useEffect(() => {
    postInfractionList();
  }, [payload]);

  return (
    <MedListContext.Provider value={{ medList, payload, pagination, updatePagination, updateOrder, updateFilter }}>
      {children}
    </MedListContext.Provider>
  );
};

export default MedListContext;
