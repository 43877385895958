import { CircleDollarSign, Download, Filter, RefreshCcw } from "lucide-react";
import { Button } from "@/shared/components/ui/button";
import { InfoCard } from "@/shared/components/Card/info";

export default function Dashboard() {
  return (
    <>
      <div className="w-full border-b border-[#E5E5E5] flex justify-between items-center">
        <span className="text-base font-semibold border-b-2 border-[#636363] py-3 px-3">Visão Geral</span>
        <div className="">
          <Button variant="ghost" className="text-states-info gap-2">
            <Filter />
            Filtros
          </Button>
          <Button variant="ghost" className="text-states-info gap-2">
            <Download />
            Baixar planilha
          </Button>
        </div>
      </div>
      <div className="flex flex-col rounded bg-white p-4 mt-6">
        <div className="flex justify-evenly gap-2">
          <InfoCard className="flex-1">
            <div className="flex gap-6">
              <div className="flex flex-col">
                <span className="font-semibold text-2xl">R$ 2M</span>
                <span className="font-normal text-sm text-[#EF5875]">Total Acumulado</span>
              </div>

              <div className="flex flex-col">
                <span className="font-semibold text-2xl">R$ 50.792</span>
                <span className="font-normal text-sm text-[#EF5875]">Saldo Diário</span>
              </div>
            </div>

            <div className="flex gap-6">
              <div className="flex flex-col">
                <span className="font-semibold text-2xl">R$ 64.700</span>
                <span className="font-normal text-sm text-[#EF5875]">Entrada do Dia</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold text-2xl">R$ 13.908</span>
                <span className="font-normal text-sm text-[#EF5875]">Saída do Dia</span>
              </div>
            </div>
          </InfoCard>
          <InfoCard className="flex-1">
            <div className="flex flex-col">
              <span className="font-semibold text-2xl">250</span>
              <span className="font-normal text-sm text-[#EF5875]">Transações efetuadas</span>
            </div>
            <div className="flex gap-6">
              <div className="flex flex-col">
                <span className="font-semibold text-2xl">180</span>
                <span className="font-normal text-sm text-[#EF5875]">Entradas</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold text-2xl">70</span>
                <span className="font-normal text-sm text-[#EF5875]">Saídas</span>
              </div>
            </div>
          </InfoCard>
          <InfoCard className="flex-1 justify-between">
            <div>
              <CircleDollarSign className="text-[#EF5875]"/>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold text-2xl">R$ 1.566,41</span>
              <span className="font-normal text-sm text-[#EF5875]">Ticket Médio</span>
            </div>
          </InfoCard>
          <InfoCard className="flex-1 justify-between">
            <div>
              <RefreshCcw className="text-[#EF5875]"/>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold text-2xl">80%</span>
              <span className="font-normal text-sm text-[#EF5875]">Taxa de Conversão</span>
            </div>
          </InfoCard>
        </div>
      </div >
    </>
  )
};
