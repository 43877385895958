import { cn } from "../../../../../shared/libs/utils";
import { formatCpfCnpj, formatDate, formatStatus } from "../../../../../shared/libs/format";
import { Info } from "./info";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../../../../shared/components/ui/select";

type Props = {
  data: {
    status: string
    created_at: string
    deadline: string
    doc_payer: string
    type: string
  }
}

export function DataRequisition({ data }: Props) {
  const [createdDate, createdHour] = formatDate(data.created_at)
  const [deadlineDate, deadlineHour] = formatDate(data.deadline)

  return (
    <div className="flex gap-3 py-4 border-b border-[#E5E5E5]">
      <div className="flex flex-col gap-1 flex-1">
        <span className="font-semibold text-xl">Dados da Requisição</span>
        <span className="text-sm">Detalhes sobre o pedido de devolução aberto e prazos de ação</span>
      </div>
      <div className="flex flex-col gap-4 flex-1">
        <Info title="Status:">
          <div className={cn(
            "font-semibold text-sm py-1 px-2 rounded-full border",
            data.status === "approved automatic" && "text-states-info border-states-info",
            data.status === "urgent" && "text-states-error border-states-error",
            data.status === "resolved" && "text-states-sucess border-states-sucess",
          )}>
            {formatStatus(data.status)}
          </div>
        </Info>
        <Info title="Data de Criação:">
          <span>{createdDate}, {createdHour}</span>
        </Info>
        <Info title="Data de Limite:">
          <div className={cn(
            "font-semibold text-sm py-1 px-2 rounded-full border",
            data.status === "approved automatic" && "text-states-info border-states-info",
            data.status === "urgent" && "text-states-error border-states-error",
            data.status === "resolved" && "text-states-sucess border-states-sucess",
          )}>
            <span>{deadlineDate}, {deadlineHour}</span>
          </div>
        </Info>
        <Info title="Documento do pagador:">
          <span>{formatCpfCnpj(data.doc_payer)}</span>
        </Info>
        <Info title="Tipo de Infração:">
          <Select>
            <SelectTrigger className="w-44 h-9 bg-[#EFEEF2]">
              <SelectValue placeholder="[tipo]" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="application_fraud">Fraude</SelectItem>
                <SelectItem value="mule_account">Laranjinha</SelectItem>
                <SelectItem value="scammer_account">Scam</SelectItem>
                <SelectItem value="other">Outros</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </Info>
      </div>
      <div className="flex flex-col gap-4 flex-1">
        <div className="flex gap-2">
          <span className="font-semibold text-sm">Resumo:</span>
          <span>Infração recebida por solicitação de outra conta</span>
        </div>
        <div className="flex gap-2">
          <span className="font-semibold text-sm">Detalhes:</span>
          <span>Golpista faz anúncios falsos ou copiados em plataformas digitais. Após recebimento de pagamento não realiza entrega do produto/serviço.</span>
        </div>
      </div>
    </div>
  )
}
