import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../../../../shared/components/ui/table";
import { formatCpfCnpj, formatDate } from "../../../../../shared/libs/format";
import { Eye } from "lucide-react";
import { StatusCell } from "../../Table/cell";
import { Button } from "../../../../../shared/components/ui/button";

const mockData = [
  {
    id: 1,
    status: 'Pendente',
    created_at: '2023-01-01T00:00:00.000Z',
    document: '12345678901',
    response: 'Não',
  },
  {
    id: 2,
    status: 'Pendente',
    created_at: '2023-01-02T00:00:00.000Z',
    document: '12345678902',
    response: 'Não',
  },
  {
    id: 3,
    status: 'Pendente',
    created_at: '2023-01-03T00:00:00.000Z',
    document: '12345678903',
    response: 'Não',
  },
]

export function DataHistoric() {
  return (
    <div className="flex gap-3 py-4 border-b border-[#E5E5E5]">
      <div className="flex flex-col gap-1 w-90">
        <span className="font-semibold text-xl">Solicitações</span>
        <span className="text-sm">Lista de solicitações de devolução feitas pelo mesmo pagador</span>
      </div>
      <div className="flex flex-col w-full">
        <Table className="rounded w-3/4">
          <TableHeader className="bg-[#F3F3F3]">
            <TableRow>
              <TableHead className="font-semibold text-sm text-[#414141]">Status</TableHead>
              <TableHead className="font-semibold text-sm text-[#414141]">Data de solicitação</TableHead>
              <TableHead className="font-semibold text-sm text-[#414141]">Docum. Pagador</TableHead>
              <TableHead className="font-semibold text-sm text-[#414141]">Resposta</TableHead>
              <TableHead className="font-semibold text-sm text-[#414141]">Ação</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {mockData.map((item) => {
              const [createdDate, createdTime] = formatDate(item.created_at)

              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <StatusCell status={item.status} />
                  </TableCell>
                  <TableCell>
                    <span>{createdDate} às {createdTime}</span>
                  </TableCell>
                  <TableCell>
                    <span>{formatCpfCnpj(item.document)}</span>
                  </TableCell>
                  <TableCell>
                    <span>{item.response}</span>
                  </TableCell>
                  <TableCell>
                    <Button variant="ghost" className="text-states-info">
                      <Eye />
                    </Button>
                  </TableCell>
                </TableRow>)
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
