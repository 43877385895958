import { Button } from "@/shared/components/ui/button";
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle, SheetTrigger, SheetClose } from "@/shared/components/ui/sheet";
import { Filter, Info } from "lucide-react";
import { CalendarInput, GeneralDateInput, GeneralInput, SelectInput } from "./input";
import { useState } from "react";
import { isBefore } from 'date-fns';
import { useToast } from "@/shared/hooks/use-toast";
import { useMedList } from "@/presentation/Med/contexts/med-list";

export function AdvanceFilter() {
  const { toast } = useToast()
  const [isOpen, setIsOpen] = useState(false)
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [infrastructure, setInfrastructure] = useState<string>("")
  const [reference, setReference] = useState<string>("")
  const [transaction, setTransaction] = useState<string>("")
  const [expiration, setExpiration] = useState<Date | undefined>(undefined)
  const [doc, setDoc] = useState<string>("")
  const [conciliation, setConciliation] = useState<string>("")
  const [workstatus, setWorkstatus] = useState<string>("every")

  const { updateFilter } = useMedList();

  const handleFilter = () => {
    updateFilter({
      infractionReportKey: infrastructure,
      idRequest: transaction,
      pixTransferKey: '',
      status: workstatus,
      payerDocumentNumber: doc,
      receiverConciliationId: conciliation,
      expirationDate: expiration,
      createdAt: {
        start: startDate,
        end: endDate,
      },
    })
  }

  const handleDateChange = (type: 'startDate' | 'endDate', date: Date | undefined) => {
    if (date) {
      if (type === 'endDate') {
        if (startDate && isBefore(date, startDate)) {
          toast({
            variant: "destructive",
            title: "Data final deve ser maior ou igual a data inicial",
          })
          return;
        }

        setEndDate(date)
      }

      setStartDate(date)
    }
  };

  const handleCancel = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    setInfrastructure('')
    setTransaction('')
    setExpiration(undefined)
    setDoc('')
    setConciliation('')
    setWorkstatus('every')
    setIsOpen(false)
  }

  return (
    <Sheet onOpenChange={setIsOpen} open={isOpen} >
      <SheetTrigger asChild>
        <Button variant="outline" className=" text-states-info gap-2 h-10">
          <Filter />
          <span className="font-bold text-base">Busca avançada</span>
        </Button>
      </SheetTrigger>
      <SheetContent className="min-w-[640px] p-8 flex flex-col justify-between">
        <div>
          <SheetHeader className="mb-6">
            <SheetTitle>Busca Avançada</SheetTitle>
            <SheetDescription>Aqui você consegue filtrar de maneira refinada as solicitações de MED</SheetDescription>
          </SheetHeader>
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <span className="font-bold">Período de Tempo</span>
                <span className="text-sm flex items-end gap-2">
                  <Info className="text-white bg-states-info rounded-lg" />
                  Limite de período de busca semanal
                </span>
              </div>
              <div className="flex justify-between">
                <CalendarInput label="startDate" date={startDate} setDate={handleDateChange} />
                <CalendarInput label="endDate" date={endDate} setDate={handleDateChange} />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <span className="font-bold">Parâmetros da Infração</span>
              <div className="flex items-center gap-4">
                <GeneralInput label="ID da Infração" value={infrastructure} setValue={setInfrastructure} id="infrastructure" placeholder="ex.: IDMED12" />
                <GeneralInput label="ID da Transação" value={transaction} setValue={setTransaction} id="transaction" placeholder="ex.: RECEIV001" />
                <GeneralDateInput label="Data de Expiração MED" value={expiration} setValue={setExpiration} id="expiration-date" placeholder="12/09/2024" />
              </div>
              <div className="flex items-center gap-4">
                <GeneralInput label="Reference Key" value={reference} setValue={setReference} id="reference" placeholder="ex.: REF0001" />
                <SelectInput label="Status" value={workstatus} setValue={setWorkstatus} id="workstatus"  />
              </div>
              <div className="flex items-center gap-4">
                <GeneralInput label="Doc. do Pagador" value={doc} setValue={setDoc} id="doc" placeholder="ex.: 123.456.789-00" />
                <GeneralInput label="Conciliation ID" value={conciliation} setValue={setConciliation} id="conciliation" placeholder="ex.: CON1234" />
              </div>
            </div>
          </div>
        </div>
        <SheetFooter>
          <SheetClose className="w-full"><Button variant="outline" className="w-full border-secondary-dark text-secondary-dark font-bold text-base" onClick={handleCancel}>Cancelar</Button></SheetClose>
          <Button className="w-full bg-secondary-dark text-white font-bold text-base" onClick={handleFilter}>Filtrar</Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
