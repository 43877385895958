type Props = {
  src: string
  alt: string
}

export const ImageContainer = ({ src, alt }: Props) => (
  <div className="w-[542px] h-[490px]">
    <img src={src} alt={alt} className="w-full h-auto" />
  </div>
);
