import { Logo } from "@/shared/components/Logo";
import { MenuItem } from "./menu";
import { MENU_OPTIONS } from "./menu-option";
import { useLocation } from "react-router-dom";

export function Sidebar() {
  const { pathname } = useLocation();

  return (
    <div className="w-[216px] h-screen bg-primary-dark overflow-hidden">
      <div className="flex flex-col w-full h-full">
        <div className="py-5 px-4 border-b border-[#C6C3D0]"><Logo /></div>

        <div className="flex flex-col w-full h-full py-8">
        {MENU_OPTIONS.map((option, index) => (
          <MenuItem key={index} name={option.name} link={option.path} icon={option.icon} path={pathname} />
        ))}
      </div>
      </div>
    </div>
  )
}
